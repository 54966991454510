<!-- add by Liwh time:2024-09-01 description:轮播图编辑页面 -->

<template>
    <el-container class="Wrapper">
        <el-container class="containerse">
          <el-main>
            <div class="addition">
              <el-form :label-position="label - postion">
                <p>
                  <!-- radio 1.文章 2.商品 -->
                  <span class="name"><i style="color: red;">*</i>轮播图:</span>
                  <span class="avatar1">

                                <el-upload class="avatar-uploader" action="" :http-request="upload_1"
                                           :show-file-list="false" list-type="picture-card">
                                    <img :key="image1" v-if="image1 != '' && image1 != undefined"
                                         :src="image1" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="image1 != '' && image1 != undefined" @click="deletes1"
                                     class="delete" src="../assets/delete.png">
                            </span>
                </p>

                <p>
                  <span class="name"><i style="color: red;">*</i>关联内容：</span>
                  <span class="inputs">
                    <el-select v-model="valuese" placeholder="请选择要关联的内容" @change="setTypeNumber" prop="typeNumber">
                        <el-option v-for="item in managementese" :key="item.value" :label="item.label"
                                   :value="item.value" />
                    </el-select></span>
                </p>
                <p v-if="typeNumber == '1'">
                  <span class="name"><i style="color: red;">*</i>文章标题:</span>
                  <span class="input"> <el-input v-model="headline"  placeholder="请输入内容" type="textarea" rows="1" /></span>
                </p>
                <p v-if="typeNumber == '1'">
                  <span class="name"><i style="color: red;">*</i>文章内容:</span>
                  <span class="input"> <el-input v-model="contentArticle"  placeholder="请输入内容" type="textarea" rows="15" /></span>
                </p>

                <p v-if="typeNumber == '2'">
                  <span class="name"><i style="color: red;">*</i>商品信息:</span>
                  <span class="input"> <el-input v-model="productNames"  placeholder="请选择关联的商品" readonly @click="openProductDialog"/></span>
                  <span class="input" style="display: none"> <el-input  v-model="productIds" maxlength="100" placeholder="请选择关联的商品"/></span>
                </p>

                <p class="button">
                  <el-button @click="save" type="primary">保存</el-button>
                  <el-button @click="concel" type="primary">返回</el-button>
                </p>
                <p></p>
              </el-form>

            </div>
          </el-main>
        </el-container>
    </el-container>

  <chooseRelativeProductDialog v-model:modelValue="isReasonDialogVisible" @confirm="setRelativeProduct"/>

</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import instance from '../network/storester.js'
import chooseRelativeProductDialog from './ChooseRelativeProductDialog'
import {ref, toRaw} from 'vue'


const router = useRouter()
const route = useRoute()
const contentArticle = ref('');
const typeNumber = ref('');
const productNames = ref('');
const productIds = ref('');
const isReasonDialogVisible = ref(false);
const image1 = ref('');
const img1 = ref('');
const valuese = ref('')
const headline = ref('')
const storeId = ref('')

const upload_1 = (param) => {
  console.log(param);
  const formData = new FormData();
  formData.append('file', param.file);
  console.log(formData);
  instance.post('/api/common/upload', formData)
      .then(response => {
        console.log('上传图片成功');
        console.log(response.data.data);
        //本地
        img1.value = response.data.data;
        //请求
        instance.get(`/api/common/download?name=${response.data.data}`)
            .then(response => {
              image1.value = response.request.responseURL
              console.log(image1.value)
            }).catch(error => {
          console.log(error);
          /*
          ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
          })
          router.push('/')*/
        })
        console.log(img1.value);
      }).catch(response => {
    console.log('图片上传失败')
  })
};

const deletes1 = () => {
  image1.value = "";
  img1.value = "";
}

const setTypeNumber = (value) => {
  typeNumber.value = value;

}

const openProductDialog =() =>{
  isReasonDialogVisible.value = true
}

const setRelativeProduct = (value) => {
  productIds.value = value.id;
  productNames.value = value.name;
  storeId.value = parseInt(value.storeId)
}


instance.get(`/api/common/download?name=${route.query.img1}`)
    .then(response => {
      image1.value = response.request.responseURL
      img1.value = route.query.img1
    }).catch(error => {
        console.log(error);
        /*ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')*/
    })
contentArticle.value = route.query.contentArticle;
//判断该视频的分类
valuese.value = route.query.typeNumber
typeNumber.value = route.query.typeNumber
productIds.value = route.query.productIds
productNames.value = route.query.productNames


const concel = () => {
    router.push('./carousel')
}

const managementese = [
    {
        value: '1',
        label: '文章',
    },
    {
        value: '2',
        label: '商品',
    }
]

//保存信息
const save = () => {
    ElMessageBox.confirm(
        '确定保存?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            //轮播图关联文章内容，校验文章内容是否为空
            if(valuese.value == "1" && contentArticle.value === '' && headline.value === ''){
              ElMessage({
                type: 'error',
                message: '文章内容不能为空',
              })
              return;
            }

            //判断轮播图是否为空
            if (img1.value === '') {
              ElMessage({
                type: 'error',
                message: '轮播图为空',
              })
              return;
            }
            //存储api需要信息
            const ade = {
              "carousel":{
                "id": route.query.id,
                "storeId":storeId.value,
                "contentArticle": contentArticle.value,
                //"img1": image1.value,
                "img1":img1.value,
                "typeNumber": valuese.value,
                "headline":headline.value,
              },
              "relativeProductIdList":productIds.value ? String(productIds.value).split(","):[]
            }
            //保存轮播图信息
            instance.post(`api/carousels/saveCarouseInfo`, ade)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '保存成功',
                    })
                    router.push('./carousel')
                }).catch(() => {
                    ElMessage({
                        type: 'error',
                        message: '保存失败',
                    })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消操作',
            })
        })
}




</script>

<style>
.addvideo {
    /* display: flex; */
    width: 100vw;
    height: 100vh;
    margin-top: 10vh;
}

.addvideo span {
    font-size: 1rem;
}

.addvideo .bus {
    /* padding-left: 1vw; */
    margin-left: 1vw;
}

.addvideo .el-upload video {
    width: 20vw;
    height: 10vw;
}
.addvideo .el-select{
width: 20vw;
}
.avatar1 {
  position: relative;
}
.containerse {
  position: fixed;
  top: 10vh;
  left: 15vw;
}



.addition {
  width: 100vw;
  height: 80vh;
}

.addition .el-input {
  width: 25vw;
}

.addition p {
  padding-top: 5vh;
  display: flex;
}

.addition .el-textarea {
  width: 25vw;
}

.name {
  width: 9vw;
}

.addition .input {
  display: flex;
}

.addition .button {
  display: flex;
  margin-left: 12vw;
}
</style>