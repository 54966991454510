<!-- 用户积分 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="allIntegral">
                <div class="allIntegralhead">
                    <el-input v-model="userinput" placeholder="请输入用户ID" @keyup.enter="alllnveQuery"
                        class="allIntegralSearch"></el-input>
                    <el-button type="primary" @click="alllnveQuery" class="alluserSearchBtn">查询</el-button>
                </div>
                <div class="allIntegralbody">
                    <el-table :data="AllIntegral" height="75vh" border style="width: 100%" v-loading="loading"
                        element-loading-text="加载中，请稍后...">
                        <el-table-column fixed prop="wxUserId" label="用户ID" width="200px" />
                        <el-table-column prop="nickName" label="用户昵称" width="200px" />
                        <el-table-column prop="balance" label="积分余额" width="200px" />
                        <el-table-column prop="pointsEarned" label="获得积分数量" width="200px">
                        </el-table-column>
                        <el-table-column prop="pointsUsed" label="消耗积分数量" width="200px">
                        </el-table-column>
                        <el-table-column prop="avatarUrl" label="头像" width="200px">
                            <template #default="scope">
                                <div style="width: 50px;height: 50px;display: flex;align-items: center;"
                                    v-if="scope.row.avatarUrl !== null && scope.row.avatarUrl !== ''">
                                    <el-image fit="fill" lazy :src="`${scope.row.avatarUrl}`">
                                    </el-image>
                                </div>
                                <div style="width: 60px;height: 60px;display: flex;align-items: center;" v-else>
                                    <span>暂无头像</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gender" label="性别" width="200px">
                            <template #default="scope">
                                <span v-if="scope.row.gender == 0 || scope.row.gender == null">未知</span>
                                <span v-else-if="scope.row.gender == 1">男</span>
                                <span v-else-if="scope.row.gender == 2">女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="200px">
                        </el-table-column>
                        <el-table-column prop="updateTime" label="更新时间" width="200px">
                        </el-table-column>
                        <el-table-column fixed="right" prop="" label="操作" width="200px">
                            <template #default="scope">
                                <el-button @click="handleClick(scope.row.wxUserId)" type="primary">
                                    查看
                                </el-button>
                                <el-drawer v-model="visible" :open-delay="300" :show-close="false" @close="closeDrawer">
                                    <template #header="{ close, titleId, titleClass }">
                                        <h4 :id="titleId" :class="titleClass">查看积分详情</h4>
                                        <el-button type="danger" @click="close">
                                            <el-icon class="el-icon--left">
                                                <CircleCloseFilled />
                                            </el-icon>
                                            关闭
                                        </el-button>
                                    </template>
                                    <p class="allIntegralp" v-for="(item) in alldetails">
                                        <el-descriptions border :size="size" :column="1">
                                            <el-descriptions-item label="获取途径:">{{ item.name }}</el-descriptions-item>
                                            <el-descriptions-item label="获得积分:">{{ item.pointsEarned
                                                }}</el-descriptions-item>
                                            <el-descriptions-item label="积分类型">{{ item.type }}</el-descriptions-item>
                                            <el-descriptions-item v-if="item.type === '消费积分'" label="消费积分">{{
                        item.pointsUsed }}</el-descriptions-item>
                                        </el-descriptions>
                                    </p>
                                </el-drawer>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="allIntegralfoot">
                    <div class="demo-pagination-blocks">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                            @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
import { ElButton, ElDrawer } from 'element-plus'
import { CircleCloseFilled } from '@element-plus/icons-vue'

const AllIntegral = ref([])
const AllIntegrala = ref(null)
const userinput = ref('')
const loading = ref(true)
const tatol = ref(0);
const router = useRouter();
const visible = ref(false)

const page = reactive({ pageNum: 1, pageSize: 10 });
instance.get(`/api/WxUserPoints/getuserCreditsRule?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        console.log(response)
        AllIntegrala.value = response.data.data.userVOList
        AllIntegral.value = toRaw(AllIntegrala.value)
        tatol.value = response.data.data.totalCount
        loading.value = false
    })
    .catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })


const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/WxUserPoints/getuserCreditsRule?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            console.log(response)
            AllIntegrala.value = response.data.data.userVOList
            AllIntegral.value = toRaw(AllIntegrala.value)
            tatol.value = response.data.data.totalCount
            loading.value = false
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
const alldetails = ref([])
const alldetailsa = ref('')
const handleClick = (wxUserId) => {
    visible.value = true
    instance.get(`/api/WxUserPoints/getUserByIdCeditDetails?userId=${wxUserId}`)
        .then(response => {
            console.log(response);
            alldetailsa.value = response.data.data
            alldetails.value = toRaw(alldetailsa.value)
            console.log(alldetails.value);

        }).catch(error => {
            console.log(error)
        })
}
const closeDrawer = () => {
    visible.value = false;
    console.log('关闭');
}

</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}


.allIntegral {
    margin-left: -4vw;
    margin-top: 7vh;
    width: 100vw;
    height: 100vh;
}

.allIntegralbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.allIntegralfoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.allIntegralhead {
    margin-top: 3vh;
}

.allIntegralSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;

}

.allIntegralSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.allIntegralfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}

.allIntegralbody .el-table--border .el-table__inner-wrapper::after {
    z-index: 1;
}

.allIntegralbody .el-table__row .el-table__cell {
    width: 201px !important;
}

.allIntegralbody .el-table .cell {
    width: 200px !important;
}

.allIntegralp {
    margin-bottom: 1vh;
}

.allIntegralbody .el-overlay {
    background-color: var(--el-overlay-color-lighter);
}
</style>