<!-- 分类管理 -->

<template>
  <el-container class="Wrapper">
    <el-container class="classify-container">
	
			<el-aside class="classify-aside" width="250px">
 				<el-menu>
					<el-menu-item v-for="(item, index) in bleDatas" :index="item.id" @click="getcate(item.id)">
							<span>{{item.name}}</span>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main class="classify-main">
				<el-table
				  ref="tableRef"
				  row-key="id"
					class="table-style"
				  :data="tableDatas"
					empty-text="请先从左侧选择店铺"
				  stripe
					border
				  height="100%"
				>
				<el-table-column type="expand">
				      <template #default="props">
								
								<h3 v-if="props.row.children" style="margin-left: 20px;">二级分类</h3>
								<h3 v-else style="margin-left: 20px;">该分类没有二级分类</h3>
								<el-table v-if="props.row.children" :data="props.row.children" style="margin: 10px;width: 90%;">
									<el-table-column type="index" label="序号" width="70" />
									<el-table-column prop="id" label="分类ID" width="200" />
									<el-table-column prop="name" label="分类名称" show-overflow-tooltip />
									<el-table-column prop="sortNum" label="排序" width="70" />
									<el-table-column label="操作" width="300" fixed="right">
										<template #default="{ row }">
											<el-button type="primary" @click="openDialog(row)"
												:icon="Edit">编辑</el-button>
											<el-button type="danger" @click="danger(props.row.id)" :icon="Delete">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
				      </template>
					</el-table-column>
				  <el-table-column type="index" label="序号" width="70" />
				  <el-table-column prop="id" label="分类ID" width="200" />
				  <el-table-column prop="name" label="分类名称" show-overflow-tooltip />
				  <el-table-column prop="sortNum" label="排序" width="70" />
				  <el-table-column label="操作" width="300" fixed="right">
				    <template #default="{ row }">
							<!-- primary(scope.row.id, scope.row.storeId) -->
							<el-button type="primary" @click="openDialog(row)"
							  :icon="Edit">编辑</el-button>
							<el-button type="danger" @click="danger(row.id)" :icon="Delete">删除</el-button>
				    </template>
				  </el-table-column>
				</el-table>
				<el-dialog
				    v-model="dialogVisible"
						class="updateDialog"
				    title="修改分类"
				    width="30%"
				  >
				    <el-form :model="dialogForm" label-width="80px">
							<el-form-item label="上级分类">
								<el-select v-model="dialogForm.parentId" :disabled="dialogForm.children && dialogForm.children.length > 0 ? true:false">
									<el-option label="无" :value="0" />
									<el-option
										v-for="item in tableDatas"
										:key="item.id"
										:label="item.name"
										:value="item.id"
									/>
								</el-select>
							</el-form-item>
							<el-form-item label="分类名称">
								<el-input v-model="dialogForm.name"></el-input>
							</el-form-item>
							<el-form-item label="排序">
								<el-input v-model="dialogForm.sortNum"></el-input>
							</el-form-item>
						</el-form>
				    <template #footer>
				      <div class="dialog-footer">
				        <el-button @click="dialogVisible = false">取消</el-button>
				        <el-button type="primary" @click="closeDialog()">
				          确定
				        </el-button>
				      </div>
				    </template>
				  </el-dialog>
				<!-- <el-pagination
				  v-if="pagination.total"
				  v-model:current-page="pagination.current"
				  v-model:page-size="pagination.pageSize"
				  :page-sizes="[10, 15, 20, 30, 50, 100]"
				  small
				  background
				  layout="total, sizes, prev, pager, next, jumper"
				  :total="pagination.total"
				  @size-change="getList()"
				  @current-change="getList()"
				/> -->
			</el-main>
    </el-container> 
  </el-container>
</template>
<script setup>
// import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref } from 'vue';
import axios from 'axios';
import { toRaw } from 'vue';
import { useRouter } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit, Delete, Plus } from '@element-plus/icons-vue'
import _ from 'lodash'


const tableDatas = ref([]);
const bleDatas = ref([]);
const datas = ref(null);
const tabas = ref(null);
const loading = ref(true);
const router = useRouter();
const inputData = ref('')

if (localStorage.getItem('permissionsStatus') === '0') {
  instance.get('/api/stores')
    .then(response => {
      tabas.value = response.data;
      console.log(tabas.value);
      bleDatas.value = toRaw(tabas.value);
      console.log(bleDatas.value);
    }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
} else {
  instance.get(`/api/stores/${localStorage.getItem('sysStoreId')}`)
    .then((response => {
      tabas.value = response.data.data;
      bleDatas.value = [toRaw(tabas.value)]
      console.log(bleDatas.value);
    }))
    .catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
}
const getcate = (id) => {
  loading.value = true;
  instance.get(`/api/stores/cate/${id}`)
    .then(response => {
      datas.value = response.data.data;
      tableDatas.value = toRaw(datas.value);
      console.log(tableDatas.value);
      console.log(toRaw(datas.value));
      console.log(tableDatas.value);
      loading.value = false;
    }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
};
const danger = (id) => {
  //根据id删除数据库中该数据
  ElMessageBox.confirm(
    '确定要删除该分类嘛?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      instance.delete(`api/categories/${id}`)
        .then(response => {
          console.log(response);
          // loading.value = true
          if (response.data.code === 0) {
            ElMessage({
              type: 'error',
              message: `${response.data.msg}`,
            })
          } else {
            ElMessage({
              type: 'success',
              message: `${response.data.data}`,
            })
            // loading.value = false
            window.location.reload();
          }
        })

    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消删除',
      })
    })
}
const primary = (id, storeId) => {
  console.log(id);
  ElMessageBox.prompt('你确定要修改该分类嘛', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  })
    .then(({ value }) => {
      if (value.trim() === '') {
        this.$message({
          type: 'error',
          message: '输入不能为空'
        })
      } else {
        var edit = {
          "id": id,
          "name": value,
          "storeId": storeId
        }
        instance.put(`/api/categories`, edit)
          .then(response => {
            ElMessage({
              type: 'success',
              message: `修改成功，新分类名为:${value}`,
            })
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            ElMessage({
              type: 'error',
              message: `修改失败`,
            })
          })

      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消修改',
      })
    })
}

const dialogVisible = ref(false)
const dialogForm = ref()
const openDialog = (classifyItem) => {
	dialogVisible.value = true
	dialogForm.value = _.cloneDeep(classifyItem)
	console.log('dialog传值',dialogForm)
}
const closeDialog = () => {
	dialogVisible.value = false
	console.log('dialog返回值',dialogForm)
	instance.put(`/api/categories`, dialogForm.value)
	  .then(response => {
	    ElMessage({
	      type: 'success',
	      message: `修改成功`,
	    })
			getcate(dialogForm.value.storeId)
			// dialogForm.value = {
			// 		"id": '',
   //        "name": '',
   //        "storeId": '',
			// 		"sortNum": 0
			// 		}
	//     // window.location.reload();
	  })
	  .catch(error => {
	    console.log(error);
	    ElMessage({
	      type: 'error',
	      message: `修改失败`,
	    })
	  })
	
}
</script>

<style>
/* .container {
  position: fixed;
  top: 0;
  left: 15vw;
} */
.classify-container{
	position: fixed;
	top: 10vh;
	left: 12vw;
	width: 85vw;
	height: 90vh;
}
.classify-aside{
	width: 240px;
	box-shadow: 0px 0px 5px rgba(17, 16, 16, 0.3);
}
.classify-main{
	width: calc(1 - 15vw - 250px);

}

.el-dialog {
	width: 30% !important;
}

.updateDialog{
	.el-form-item{
		margin-bottom: 20px;
	}
}

.classification .el-table tr.el-table__row {
  height: 10vh !important;
  /* background-color: aqua; */
}

.classification .el-collapse-item__row {
  height: 10vh !important;
}

.classification .el-table__header {
  height: 70px !important;
}

.classification .el-collapse-item__header {
  height: 10vh !important;
}

.classification .el-button__row {
  height: 100px !important;
  /* background-color: aqua; */
}

.classification .el-collapse .but {
  position: fixed;
  margin-left: 40vw;
  margin-top: 20px;
}

.classification .el-collapse .but {
  display: inline-flex;
  position: fixed;
  margin-left: 40vw;
  margin-top: 20px;
  position: sticky;
}

.classification {
  font-size: 12px !important;
}

.classification .table-as {
  position: fixed;
  top: 8vh;
  width: 70vw;
  /* background-color: #fff; */
  left: 15vw;
  /* position: fixed; */
  /* top: -6vh; */
  /* width: 80vw; */
  height: 100vh;
  margin-top: 15%;
  box-shadow: 0px 0px 10px rgba(17, 16, 16, 0.5);
}
</style>