<!-- 所有订单 -->
<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="dang">
                        <div class="allorders">
                            <div class="zhuang">
                                <el-input style="width: 10vw;" v-model="input" placeholder="请输入订单号" @keyup.enter="query"
                                    @change="sift"></el-input>
                                <span class="Separateds">日期范围：</span>
                                <span style="width: 22vw;">
                                    <el-date-picker class="select" v-model="value2" type="datetimerange"
                                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期" align="right">
                                    </el-date-picker>
                                </span>
                                <span>
                                    <el-select style="width: 10vw;" v-model="value" filterable placeholder="请选择订单状态"
                                        @size-change="handleSizeChange" @current-change="handleCurrentChange">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value" />
                                    </el-select>
                                </span>
                                <span><el-button type="primary" @click="exports">导出</el-button> </span>
                                <span><el-button type="primary" @click="sift">查找</el-button> </span>
                            </div>

                            <div class="list">
                                <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="orderser"
                                    style="width: 100vw; " height="70vh" border>
                                    <el-table-column fixed prop="id" label="订单号" width="200px"
                                        label-class-name="ding" />
                                    <el-table-column prop="" label="订单状态" width="200px">
                                        <template #default="{ row }">
                                            <span v-if="row.orderStatus === 0"> <el-tag
                                                    type="primary">未支付</el-tag></span>
                                            <span v-if="row.orderStatus == 1"><el-tag type="success">已支付</el-tag></span>
                                            <span v-if="row.orderStatus == 2"><el-tag type="danger">待发货</el-tag></span>
                                            <span v-if="row.orderStatus == 3"><el-tag type="primary">待收货</el-tag></span>
                                            <span v-if="row.orderStatus == 5"><el-tag type="info">已退款</el-tag></span>
                                            <span v-if="row.orderStatus == 6"><el-tag
                                                    type="warning">申请退款</el-tag></span>
                                            <span v-if="row.orderStatus == 4"><el-tag type="success">已完成</el-tag></span>
                                            <span v-else-if="row.orderStatus === 7"><el-tag
                                                    type="primary">库存不足</el-tag></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="shippingMethod" label="物流方式" width="200px" />
                                    <el-table-column prop="address" label="收货人地址" width="200px" />
                                    <el-table-column prop="storeName" label="商品门店" width="200px" />
                                    <el-table-column prop="name" label="物流单号" width="200px" />
                                    <el-table-column prop="consignee" label="收货人名称" width="200px" />
                                    <el-table-column prop="mobile" label="收货人电话" width="200px" />
                                    <el-table-column prop="message" label="用户留言" width="200px" />
                                    <el-table-column prop="goodsName" label="商品名称" width="200px" />
                                    <el-table-column prop="orderPrice" label="订单总价格" width="200px" />
                                    <el-table-column prop="addOrderTime" label="订单创建时间" width="200px" />
                                    <el-table-column prop="address" fixed="right" label="操作" width="200px"
                                        #default="{ row }" class="cao">
                                        <el-button type="primary"
                                            v-if="row.shippingMethod === '快递配送（普通快递）' && (row.orderStatus === 2 || row.orderStatus === 1)"
                                            @click="shipments(row.id, row.goodsName, row.mobile, row.openId, row.orderStatus, row.wxUserId, row.addOrderTime,
                                                row.storeId, row.message, row.orderPrice, row.goodsId, row.goodsNumber, row.goodsUrl, row.shippingMethod, row.storeName, row.storeTxImg)">发货</el-button>
                                        <el-button type="primary"
                                            v-else-if="row.orderStatus !== 5 && row.orderStatus !== 0 && row.orderStatus !== 4 && row.orderStatus !== 6 && row.orderStatus !== 7"
                                            @click="opes(row.id, row.orderStatus, row.goodsId, row.inviteId)">合销</el-button>
                                        <el-button type="primary" v-if="row.orderStatus === 6 || row.orderStatus === 7"
                                            @click="refunds(row.id, row.goodsName, row.mobile, row.openId, row.orderStatus, row.wxUserId, row.addOrderTime,
                                                row.storeId, row.message, row.orderPrice, row.goodsId, row.goodsNumber, row.goodsUrl, row.shippingMethod, row.storeName, row.storeTxImg, row.ordersSystemType)">全额退款</el-button>
                                        <el-button type="primary" class="part"
                                            v-if="row.orderStatus === 6 || row.orderStatus === 7"
                                            @click="refund(row.id, row.goodsName, row.mobile, row.orderStatus, row.wxUserId, row.addOrderTime,
                                                row.storeId, row.message, row.orderPrice, row.goodsId, row.goodsNumber, row.goodsUrl, row.shippingMethod, row.storeName, row.storeTxImg, row.ordersSystemType)">部分退款</el-button>
                                        <el-button type="primary" id="details"
                                            @click="detail(row.id, row.goodsId)">详情</el-button>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="demo-pagination-block">
                                <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                    :page-sizes="[10, 20, 50, 100]" :small="small" :disabled="disabled"
                                    :background="background" layout="total, sizes, prev, pager, next, jumper"
                                    :total="total" @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange" />
                            </div>
                            <div class="PopUps">
                                <div v-if="showModals" class="commentmodeae">
                                    <div class="modals-content">
                                        <span class="close" @click="toggleModals">&times;</span>
                                        <span class="edadmin">商品详情</span>
                                        <el-form :model="form" label-width="auto"
                                            style="max-width: 30vw; margin-top: 5vh">
                                            <el-form-item label="订单号">
                                                <span>{{ detailes.id }}</span>
                                            </el-form-item>
                                            <el-form-item label="收货名称">
                                                <span>{{ detailes.consignee }}</span>
                                            </el-form-item>
                                            <el-form-item label="订单状态：">
                                                <span v-if="detailes.orderStatus === 0"> <el-tag
                                                        type="primary">未支付</el-tag></span>
                                                <span v-if="detailes.orderStatus == 1"><el-tag
                                                        type="success">已支付</el-tag></span>
                                                <span v-if="detailes.orderStatus == 2"><el-tag
                                                        type="danger">待发货</el-tag></span>
                                                <span v-if="detailes.orderStatus == 3"><el-tag
                                                        type="primary">待收货</el-tag></span>
                                                <span v-if="detailes.orderStatus == 5"><el-tag
                                                        type="info">已退款</el-tag></span>
                                                <span v-if="detailes.orderStatus == 6"><el-tag
                                                        type="warning">申请退款</el-tag></span>
                                                <span v-if="detailes.orderStatus == 4"><el-tag
                                                        type="success">已完成</el-tag></span>
                                            </el-form-item>
                                            <el-form-item label="收货人电话">
                                                <span>{{ detailes.mobile }}</span>
                                            </el-form-item>
                                            <el-form-item label="收货人地址">
                                                <span>{{ detailes.address }}</span>
                                            </el-form-item>
                                            <el-form-item label="物流单号">
                                                <span>{{ detailes.trackingNumber }}</span>
                                            </el-form-item>
                                            <el-form-item label="用户留言">
                                                <span>{{ detailes.message }}</span>
                                            </el-form-item>
                                            <el-form-item label="商品名称">
                                                <span>{{ detailes.goodsName }}</span>
                                            </el-form-item>
                                            <el-form-item label="商品图片">
                                                <el-image style="width: 100px; height: 100px"
                                                    :src="`https://199shq.com/api/common/download?name=${detailes.goodsUrl}`"
                                                    :fit="fit"></el-image>
                                            </el-form-item>
                                            <el-form-item label="商品数量">
                                                <span>{{ detailes.goodsNumber }}</span>
                                            </el-form-item>
                                            <el-form-item label="订单总价格">
                                                <span>{{ detailes.orderPrice }}</span>
                                            </el-form-item>
                                            <el-form-item label="物流方式">
                                                <span>{{ detailes.shippingMethod }}</span>
                                            </el-form-item>
                                            <el-form-item label="送达时间">
                                                <span>{{ detailes.deliveryTime }}</span>
                                            </el-form-item>
                                            <el-form-item label="订单创建时间">
                                                <span>{{ detailes.addOrderTime }}</span>
                                            </el-form-item>
                                            <el-form-item label="门店头像">
                                                <el-image style="width: 100px; height: 100px"
                                                    :src="`https://199shq.com/api/common/download?name=${detailes.storeTxImg}`"
                                                    :fit="fit"></el-image>
                                            </el-form-item>
                                            <el-form-item label="门店名称">
                                                <span>{{ detailes.storeName }}</span>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { toRaw } from 'vue';
import { useRouter } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { clone } from 'xe-utils';

const orderser = ref([]);
const orderse = ref(null);
const loading = ref(true)
const router = useRouter();
const showModals = ref(false)
const data = reactive([]);
const total = ref(0);
const detailes = ref([]);
const detaile = ref(null);
const value2 = ref([])
const page = reactive({ pageNum: 1, pageSize: 10 });
//每一页条数改变时触发
const handleSizeChange = (val) => {
    page.pageSize = val;
    console.log(val);
    sift();
};
onMounted(() => {
    sift();
})
//当前页改变时触发
const handleCurrentChange = (val) => {
    page.pageNum = val;
    console.log(val);
    sift();
};
const exports = () => {
    if (value2.value != null) {
        addOrderTimeBegin.value = value2.value[0]
        addOrderTimeEnd.value = value2.value[1]
    }
    if (localStorage.getItem('permissionsStatus') === '0') {
        const datas = {
            "addOrderTimeBegin": addOrderTimeBegin.value,
            "addOrderTimeEnd": addOrderTimeEnd.value,
            "orderStatus": value.value,
            "pageNum": page.pageNum,
            "pageSize": page.pageSize,
            // "storeId": 0,
            "wxUserId": "",
            "id": input.value
        }
        instance.post('/api/orders/export', datas, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    } else {
        const datas = {
            "addOrderTimeBegin": addOrderTimeBegin.value,
            "addOrderTimeEnd": addOrderTimeEnd.value,
            "orderStatus": value.value,
            "pageNum": page.pageNum,
            "pageSize": page.pageSize,
            "storeId": localStorage.getItem('sysStoreId'),
            "wxUserId": "",
            "id": input.value
        }
        instance.post('/api/orders/export', datas, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
    }
}

const detail = (id) => {
    console.log(id);
    showModals.value = true
    instance.get(`/api/orders/details/` + id)
        .then(response => {
            detaile.value = response.data.data;
            console.log(response.data.data);
            detailes.value = toRaw(detaile.value);
            console.log(detailes.value);
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            // router.push('./')
        })
};
const toggleModals = () => {
    showModals.value = false;
}
const value = ref([{
    value: 1,
    label: '已支付',
}])
const options = [
    {
        value: 1,
        label: '已支付',
    },
    {
        value: 2,
        label: '待发货',
    },
    {
        value: 3,
        label: '待收货',
    },
    {
        value: 4,
        label: '已完成',
    },
    {
        value: 5,
        label: '已退款',
    },
    {
        value: 6,
        label: '申请退款',
    },
    {
        value: '',
        label: '全部订单',
    },]

const state = ref([]);
const states = ref([])


const input = ref('');
// const query = () => {
//     loading.value = true
//     console.log(input.value);
//     if (input.value == '') {
//         sift();
//     } else {
//         instance.get(`/api/orders/details/${input.value}`)
//             .then(responses => {
//                 console.log(responses.data.data);
//                 if (responses.data.data == null) {
//                     ElMessage({
//                         type: 'error',
//                         message: `该订单号未查找到，是否输入错误`,
//                     })
//                     loading.value = false
//                     return;
//                 }
//                 orderser.value.splice(0, orderser.value.length);
//                 console.log(orderser.value);
//                 orderser.value.push(responses.data.data);
//                 loading.value = false
//             })
//             .catch(error => {
//                 console.error(error);
//                 ElMessage({
//                     type: 'error',
//                     message: `请重新登录`,
//                 })
//                 router.push('/')
//             });
//     }
// };


const refund = (id, goodsName, mobile, orderStatus, wxUserId, addOrderTime,
    storeId, message, orderPrice, goodsId, goodsNumber, goodsUrl, shippingMethod, storeName, storeTxImg, ordersSystemType) => {
    console.log(id);
    ElMessageBox.prompt('是否确认退款', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            if (value === '') {
                ElMessage({
                    type: 'error',
                    message: `退款失败,金额为空`,
                })
            } else {
                if (orderStatus !== 0 && orderStatus !== 4 && orderStatus !== 5) {
                    if (value <= orderPrice) {
                        orderPrice = orderPrice * 100
                        value = value * 100
                        const orderPrices = parseInt(orderPrice);
                        const values = parseInt(value);
                        let refundable = {
                            "outTradeNo": id,
                            "total": orderPrices,
                            "refund": values
                        }
                        console.log(refundable);
                        instance.post(`/api/pay/payRefund`, refundable)
                            .then(response => {
                                console.log(response.data);
                                console.log('修改成功');
                                ElMessage({
                                    type: 'success',
                                    message: `退款成功，退款金额为${value}`,
                                })
                                sift()
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    } else {
                        ElMessage({
                            type: 'error',
                            message: `退款失败，金额大于或等于用户付款金额`,
                        })
                    }

                } else {
                    if (orderStatus === 0) {
                        ElMessage({
                            type: 'error',
                            message: `退款失败，该用户未支付`,
                        })
                    }
                    if (orderStatus === 4) {
                        ElMessage({
                            type: 'error',
                            message: `退款失败，订单已完成`,
                        })
                    }
                    if (orderStatus === 5) {
                        ElMessage({
                            type: 'error',
                            message: `退款失败，订单已退款`,
                        })
                    }

                }

            }
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消退款',
            })
        })
}
const refunds = (id, goodsName, mobile, openId, orderStatus, wxUserId, addOrderTime,
    storeId, message, orderPrice, goodsId, goodsNumber, goodsUrl, shippingMethod, storeName, storeTxImg, ordersSystemType) => {
    console.log(id);
    console.log(orderPrice);
    console.log(orderStatus);
    console.log(ordersSystemType)
    ElMessageBox.confirm(
        '您是否全额退款?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            if (orderStatus === 6) {
                orderPrice = orderPrice * 100
                const orderPrices = parseInt(orderPrice);
                const values = parseInt(orderPrice);
                console.log(orderPrices);
                console.log(values);
                let refundable = {
                    "outTradeNo": id,
                    "total": orderPrices,
                    "refund": values
                }
                console.log(refundable);
                instance.post(`/api/pay/payRefund`, refundable)
                    .then(response => {
                        ElMessage({
                            type: 'success',
                            message: '退款成功',
                        })
                        sift()
                    }).catch(error => {
                        console.log(error);
                    })
            } else {
                if (orderStatus === 0) {
                    ElMessage({
                        type: 'error',
                        message: `退款失败，该用户未支付`,
                    })
                }
                if (orderStatus === 4) {
                    ElMessage({
                        type: 'error',
                        message: `退款失败，订单已完成`,
                    })
                }
                if (orderStatus === 5) {
                    ElMessage({
                        type: 'error',
                        message: `退款失败，订单已退款`,
                    })
                }
            }
        }
        )
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消退款',
            })
        })
}
const addOrderTimeBegin = ref('')
const addOrderTimeEnd = ref('')
const sift = () => {
    loading.value = true
    console.log(value2.value)
    addOrderTimeBegin.value = ''
    addOrderTimeEnd.value = ''
    if (value2.value != null) {
        addOrderTimeBegin.value = value2.value[0]
        addOrderTimeEnd.value = value2.value[1]
    }
    // orderser.value.splice(0, orderser.value.length)
    if (localStorage.getItem('permissionsStatus') === '0') {
        const datas = {
            "addOrderTimeBegin": addOrderTimeBegin.value,
            "addOrderTimeEnd": addOrderTimeEnd.value,
            "orderStatus": value.value,
            "pageNum": page.pageNum,
            "pageSize": page.pageSize,
            // "storeId": 0,
            "wxUserId": "",
            "id": input.value
        }
        instance.post('/api/orders/list', datas)
            .then(res => {
                orderse.value = res.data.data.dataList;
                // console.log(res.data);
                orderser.value = toRaw(orderse.value)
                // console.log(orderser.value);
                total.value = res.data.data.total;
                loading.value = false;
            }).catch(error => {
                console.log(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })


    } else {
        const datas = {
            "addOrderTimeBegin": addOrderTimeBegin.value,
            "addOrderTimeEnd": addOrderTimeEnd.value,
            "orderStatus": value.value,
            "pageNum": page.pageNum,
            "pageSize": page.pageSize,
            "storeId": localStorage.getItem('sysStoreId'),
            "wxUserId": "",
            "id": input.value
        }
        instance.post('/api/orders/list', datas)
            .then(res => {
                orderse.value = res.data.data.dataList;
                // console.log(res.data);
                orderser.value = toRaw(orderse.value)
                // console.log(orderser.value);
                total.value = res.data.data.total;
                loading.value = false;
            }).catch(error => {
                console.log(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
    }
}

const commissions = ref('')
const money = ref('')
const opes = (id, orderStatus, goodsId, inviteId) => {
    console.log(orderStatus);
    console.log(inviteId);
    console.log(goodsId);
    // console.log(wxUserId);

    ElMessageBox.prompt('订单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0,1,2,3,4,5,6,7,8,9]+$/,
        inputErrorMessage: '订单号不正确'
    }).then(({ value }) => {
        console.log(orderStatus);
        if (orderStatus !== 0) {
            if (value === id) {
                instance.put(`/api/orders/status4/${id}`)
                    .then(response => {
                        console.log(response.data);
                        console.log('修改成功');
                        ElMessage({
                            message: '订单已完成',
                            type: 'success',
                        })
                        sift()
                    })
                    .catch(error => {
                        console.error(error);
                    });
                if (inviteId !== '') {
                    instance.get(`/api/tproduct/${goodsId}`)
                        .then(response => {
                            console.log(response.data.data);
                            commissions.value = response.data.data.commission;
                            instance.get(`/api/distribution/selectDistributionAmountByWxUserId/${inviteId}`)
                                .then(response => {
                                    console.log(response.data.data.distributionAmount);
                                    console.log(commissions.value);
                                    money.value = commissions.value + response.data.data.distributionAmount
                                    console.log(money.value);
                                    instance.put(`api/distribution?money=${money.value}&wxUserld=${inviteId}`)
                                        .then(response => {
                                            console.log(response);
                                            ElMessage({
                                                message: '订单已完成',
                                                type: 'success',
                                            })
                                            sift()
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        })
                                })
                        })
                }
            } else {
                ElMessage({
                    type: 'error',
                    message: '订单号与ID不匹配'
                });
            }
        } else {
            ElMessage({
                type: 'error',
                message: '订单状态为未支付，不能修改'
            });
        }

        console.log(value);
        console.log(id);
    }).catch(() => {
        ElMessage({
            type: 'info',
            message: '取消输入'
        });
    });
}

const shipments = (id, goodsName, mobile, openId, orderStatus, wxUserId, addOrderTime,
    storeId, message, orderPrice, goodsId, goodsNumber, goodsUrl, shippingMethod, storeName, storeTxImg) => {
    console.log(id, goodsName, mobile, openId, orderStatus);
    ElMessageBox.prompt('请输入该订单的物流单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            loading.value = true
            if (orderStatus === 2 || orderStatus === 1) {
                let shipment = {
                    "outTradeNo": id,
                    "trackingNo": value,
                    "itemDesc": goodsName,
                    "logisticsType": 1,
                    "receiverContact": mobile,
                    "openid": openId
                }
                instance.post('/api/orders/orderDelivery', shipment)
                    .then(response => {
                        ElMessage({
                            type: 'success',
                            message: `发货成功`,
                        })
                        loading.value = false
                        sift()
                    }).catch(error => {
                        console.log(error);
                        ElMessage({
                            type: 'error',
                            message: `发货失败`,
                        })
                        loading.value = false
                    })
            } else {
                ElMessage({
                    type: 'error',
                    message: `该商品未处于待发货状态`,
                })
            }


        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消发货',
            })
        })
}

</script>
<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
}

.allorders {
    display: inline-block;
    position: fixed;
    left: 11vw;
    top: 15vh;
    width: 85vw;
    height: 100vh;
    font-size: 12px !important;
}

.allorders .el-table .el-table__cell {
    text-overflow: ellipsis;
}

.allorders .OrderNumber {
    width: 20vw;
}

.allorders .el-table tr.el-table__row {
    height: 5rem !important;
    /* background-color: aqua; */
}

.allorders .el-table__inner-wrapper {
    width: 85vw !important;
    /* height: 100vh !important; */
}

/* .allorders .el-table_1_column_1 .cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    font-size: 12px !important;
} */

.allorders .el-table__header-wrapper {
    height: 8vh !important;
    font-size: 12px !important;
}

.allorders .el-table tr.el-table__row {
    height: 2vh !important;
}

.allorders .cell .el-button {
    font-size: 12px !important;
    height: 2vh !important;
}

.allorders .el-input__inner {
    display: inline-block !important;
    height: 5vh !important;
    font-size: 12px !important;
    left: 0 !important;
}

.allorders .el-pagination {
    --el-pagination-item-gap: 15px;
    font-size: 12px !important;
    margin-left: 23% !important;
    margin-top: 3vh !important;
}

.allorders .el-table .el-table__cell {
    padding: 1 !important;
}

.el-pager li {
    font-size: 1vw !important;
}

/* .allorders .el-pagination .el-select .el-input {
    width: 3vw !important;
} */

/* .allorders .OrderNumber {
    width: 10vw !important;
    position: relative !important;
    top: -4vh !important;
    left: 1vw !important;
} */
.allorders .zhuang {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* .allorders .zhuang {
    margin-top: -4vh;
} */

/* .zhuang .el-select {
    position: fixed;
    top: 11vh;
    left: 23vw;
    width: 10vw;
} */

.demo-pagination-block {
    position: fixed;
    top: 90vh;
    /* left: 25%; */
}

.allorders .ding {
    width: 200px !important;
}

.allorders .el-table .cell {
    width: 200px !important;
}

body .el-table tr td {
    display: table-cell !important;
}

.el-table-fixed-column--left:hover {
    cursor: pointer;
}

.allorders .el-table__row .el-table__cell {
    width: 200px !important;
}

#details {
    /* display: flex; */
    margin-left: 0;
    /* margin-top: -1vh; */
}

.allorders .el-button {
    margin-left: 0 !important;
}

.allorders .cao {
    padding-left: 1vw;
}

.list {
    width: 100vw;
    height: 70vh;
}

.addproductse {
    position: fixed;
    top: 10vh;
    right: 15vw;
}

.list .el-table--border .el-table__inner-wrapper::after {
    z-index: 2;
}
</style>